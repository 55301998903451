import React from 'react';
import {AusstellungTermine} from "./AusstellungTermine";
import {Image} from "../Image/Image";
import {AusstellungCarousel} from "./AusstellungCarousel";
import AusstellungsText from "../AussteullungsItem/AusstellungText";
import PageContainer from "../PageContainer/PageContainer";
import AusstellungCoverImage from "./AusstellungCoverImage";


class AusstellungsView extends React.Component {

    render() {

        const {entries} = this.props;
        const entry = entries[0];

        return (
            <PageContainer classNames={'AusstellungView'} isAusstellung>


                <div className={'container '}>
                    {/*{ color }*/}
                    {/*<button onClick={() => setColor('#ea0ca0')}>test</button>*/}
                    {/* HEADLINE TEXT BLOCK AUSTELLLUNG */}
                    <div className={"page-block"}>
                        <AusstellungsText {...entry} onlyText/>
                    </div>


                    {/*
                        LARGE COVER IMG
                        if there is an image, show it as cover image
                        (there will probably always be, because field is marked as required in backend)
                     */}
                    {
                        entry.bild[0] &&
                        <div className={"page-block"}>
                            <AusstellungCoverImage {...entry.bild[0]} />
                        </div>
                    }


                    {/* TEXT BLOCK */}

                    {
                        entry.text ||
                        entry.pdfDownloads.length ||
                        entry.termine.length ?
                            <div className={"page-block-s"}>

                                <div className={'row'}>


                                    {
                                        entry.text &&
                                        <div className="col-12 col-md-6 offset-md-1 redactor-default">
                                            {/* TEXT BLOCK LINKS */}

                                            {/* AUSTELLUNGS BESCHRIBUNG */}
                                            <p dangerouslySetInnerHTML={{__html: entry.text}}/>

                                            {/* Artists bio */}
                                            <div className="page-block-s text-tiny">
                                                {
                                                    entry.kuenstlerInnen.map(kuenstlerIn =>
                                                        kuenstlerIn.biografie.map(bio =>
                                                            <a
                                                                key={bio.title}
                                                                href={bio.url}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                ↓ {bio.title}
                                                            </a>
                                                        )
                                                    )
                                                }
                                            </div>

                                            {/* PDF LINKS */}
                                            <div className={'page-block-s text-tiny'}>
                                                {
                                                    entry.pdfDownloads.map((download, i) => {
                                                        return (
                                                            <a
                                                                href={download.url}
                                                                key={`pdf-${i}`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                ↓ {download.title}
                                                            </a>
                                                        )
                                                    })
                                                }
                                            </div>

                                        </div>
                                    }


                                    {/* TEXT BLOCK RECHTS */}
                                    <AusstellungTermine
                                        termine={entry.termine}
                                        pdfDownloads={entry.pdfDownloads}
                                        gridView={!entry.text}
                                    />


                                </div>
                            </div>
                            : null

                    }

                </div>


                <AusstellungCarousel bilder={entry.bilder}/>


            </PageContainer>

        )
    }
}

export default AusstellungsView;
