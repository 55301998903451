import React from 'react';
import {Link} from "react-router-dom";
import {Image} from "../Image/Image";
import PageContainer from "../PageContainer/PageContainer";
import strToHtml from "../../utils/strToHtml";
import PageHeader from "../PageHeader/PageHeader";
import {Addresse, Kontakt, Oeffnungszeiten} from "../Globals/Globals";

const GalerieView = props => {

    return (
        <PageContainer classNames={'GalerieView'}>

            <PageHeader>
                <h3 className={'text-uppercase text-headline'}>Galerie Monika Wertheimer</h3>
            </PageHeader>


            {/* COVER IMAGE */}
            <div className={'GalerieImages page-block'}>
                {/* TODO: Was passiert mit den weiteren Bildern? */}
                {props.entries[0].bild.map(
                    (img,i) => <Image key={img.url+i} {...img} />
                )}
            </div>


            <div className="container">


                <div className="row page-block-s">

                    <div className="col-12 col-md-7 offset-md-1 redactor-default">

                        <div className="text-tiny">DE</div>
                        <div dangerouslySetInnerHTML={{__html: props.entries[0].textDe}}/>

                        <div className="text-tiny">EN</div>
                        <div dangerouslySetInnerHTML={{__html: props.entries[0].textEn}}/>

                        <div className="my-3">
                            <h5 className="text-tiny">Folgen auf:</h5>
                            {props.globalSet.socialMediaLinks.map((medium,i) =>
                                <div
                                    className="h5 text-uppercase"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    key={medium.url+i}
                                >
                                    <a href={medium.url}>
                                        → {medium.name}
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>

                    {/*  Side Notes  */}

                    <div className={'col-12 col-md-4 redactor-tiny text-tiny'}>

                        {/*  Adresse  */}
                        <div className="mb-3">
                            <Addresse/>
                        </div>

                        {/* Kontakt */}
                        <div className="mb-3">
                            <Kontakt/>
                        </div>

                        {/* Oeffnungszeiten */}
                        <div className="mb-3">
                            <Oeffnungszeiten/>
                        </div>


                    </div>

                </div>

            </div>


            <div className="container">

                {/* PRESSE UND FREUNDE */}
                <div className={'row page-block'}>


                    <div className="col-12 col-md-6 text-left text-md-center">
                        {/* PRESSE */}
                        <h4 className={"text-subheadline"}>Presse</h4>
                        <ul className={'text-tiny list-unstyled'}>
                            {
                                props.entries[0].pdfDownloads.map((pdf, i) => {
                                    return (
                                        <li key={`pdf-${i}`}>
                                            <a href={pdf.url} target={'_blank'}
                                               rel="noopener noreferrer">{pdf.title}</a>
                                        </li>
                                    )
                                })

                            }
                        </ul>
                    </div>

                    <div className="col-12 col-md-6 text-left text-md-center">
                        {/* FREUNDE */}
                        <h4 className={"text-subheadline"}>Freunde</h4>
                        <ul className={'text-tiny list-unstyled'}>
                            {
                                props.entries[0].freunde.map((freundIn, i) => {
                                    return (
                                        <li key={`freundIn-${i}`}>
                                            <a href={freundIn.url}
                                               target={'_blak'}
                                               rel="noopener noreferrer"
                                            >
                                                {freundIn.name}
                                            </a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>


                </div>
            </div>

        </PageContainer>

    )

}

export default GalerieView;
