import React from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Image} from "../Image/Image";


export const AusstellungCarousel = props => {
    if (props.bilder.length) return (
        <div className="AusstellungCarousel">

            <Swiper
                onSwiper={(swiper) => (window.swiper = swiper)}
                slidesPerView='auto'
                grabCursor
                // slidesPerColumnFill={'row'}
            >
                {
                    props.bilder.map((bild, i) =>
                        <SwiperSlide key={`swiper-${i}`}>

                            {/*{bild.url}*/}
                            <div className={`Slide ${i + 1 === props.bilder.length ? "last" : ""}`}>
                                <img src={bild.url} alt={bild.title}/>


                                <div className="Slid-Caption">

                                    {
                                        bild.bildtitelAnzeigen &&
                                        <div>
                                            { bild.title }
                                            { bild.entstehungsjahre && <span>, {bild.entstehungsjahre}</span> }
                                        </div>
                                    }

                                    <div className="text-tiny">
                                        {bild.kuenstlerInnen.map(artist =>
                                            <span>{artist.title}</span>
                                        )}
                                        <div>
                                            {bild.technik} <br/>
                                            {bild.auflage}
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </SwiperSlide>
                    )
                }

            </Swiper>


        </div>
    )
    else return null;
}
