import React, { useEffect, useRef } from 'react';
import {ScrollPositionContext} from "../components/Providers/ScrollPositionProvider";

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export const ScrollManagerHandler = props => {
    const route = props.location.pathname
    const prevRoute = usePrevious(props.location.pathname)

    useEffect( () => {
            if ((prevRoute && prevRoute.slice(0, 12) === "/ausstellung" && route === "/ausstellungen") || (prevRoute === "/ausstellungen" && route === "/ausstellungen")) {
                window.scrollTo({top: props.top})
            } else if (route.slice(0, 10) === "/photos") {
                window.scrollTo({top: 0, behavior: "smooth"})
            } else {
                window.scrollTo({top: 0})
            }
        },
        [route, prevRoute, props.top]
    );
    return null;

}

export const ScrollManager = props => {
    return (
        <ScrollPositionContext.Consumer>
            {
                top =>
                    <ScrollManagerHandler {...top} {...props} />
            }
        </ScrollPositionContext.Consumer>
    )
}
