import React, {useEffect, useState} from "react";

export const DataCacheContext = React.createContext({
    dataCache: {
        ausstellungen: {
            status: "loading",
            data: null,
            err: null
        }
    },
    setDataCache: () => {}
});

export const DataCacheProvider = props => {

    const setDataCache = ( key, status, data, err ) => {
        setState({
            ...state,
            dataCache: {
                ...state.dataCache,
                ausstellungen: {
                    status: status,
                    data: data,
                    err: err
                }
            }
        })
    }

    const initState = {
        dataCache: {
            ausstellungen: {
                status: "loading",
                data: null,
                err: null
            }
        },
        setDataCache: setDataCache
    }

    const [state, setState] = useState(initState)


    return (
        <DataCacheContext.Provider value={state}>
            {props.children}
        </DataCacheContext.Provider>
    )
}
