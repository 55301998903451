import React from 'react';
import {SingleView} from "./SingleView";
import {GridView} from "./GridView";
import {Switch, Route} from "react-router-dom";
import PageContainer from "../PageContainer/PageContainer";
import PageHeader from "../PageHeader/PageHeader";



const PhotosView = props => {
    let currentBild = null;

    if( props.match){
        currentBild = props.entries.find(entry => `/${entry.uri}` === props.match.url)
    }

    return (
        <PageContainer classNames={'PhotosView'}>

            <PageHeader small={currentBild}>
                Photos
            </PageHeader>

            {/* CURRENT BILD */}
            <Switch>
                <Route exact component={() => SingleView( currentBild )} />
            </Switch>

            <div className="container page-block">
                <GridView {...props} />
            </div>

        </PageContainer>
    )
}

export default PhotosView;
