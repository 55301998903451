import React, {useEffect, useState} from 'react';
import axios from "axios";

import {CSSTransition} from "react-transition-group";
import AusstellungsuebersichtView from '../components/AusstellungsuebersichtView/AusstellungsuebersichtView'
import BreadcrumpChanger from "../components/Providers/BreadcrumpChanger";
import ColorChanger from "../components/Providers/ColorChanger";
import Loader from "../components/Loader/Loader";
import {DataCacheContext} from "../components/Providers/DataCacheProvider";

export const Ausstellungsuebersicht = props => (
    <DataCacheContext.Consumer>{
        context => <AusstellungsuebersichtInner {...props} {...context} />
    }</DataCacheContext.Consumer>
)

const AusstellungsuebersichtInner = props => {

    const key = "ausstellungen";

    const { status, data, error } = props.dataCache[key];
    useEffect(
        () => {

            if ( data === null ){

                props.setDataCache(key,"loading",null,null);

                axios.get(`/api/ausstellungen.json`)
                    .then(resp => {
                        props.setDataCache(key,"complete",resp.data,null);
                    })
                    .catch(err => {
                        props.setDataCache(key,"complete",null,err);
                    });
            }

        }, []
    )


    return (
        <div>

            <CSSTransition
                in={status==="loading"}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
                appear
            >
                <Loader/>
            </CSSTransition>

            <CSSTransition
                in={ typeof error !== "undefined" }
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
                appear
            >
                <p>Error </p>
            </CSSTransition>


            <CSSTransition
                in={ data !== null }
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
                appear
            >
                <div>
                    <ColorChanger hintergrundfarbe={'white'} schriftfarbe={'black'}/>
                    <BreadcrumpChanger pageInfo={data}/>
                    <AusstellungsuebersichtView {...data} />
                </div>
            </CSSTransition>

        </div>
    );

};
