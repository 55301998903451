import React from 'react';
import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {CSSTransition} from "react-transition-group";
import BreadcrumpChanger from "../components/Providers/BreadcrumpChanger";
import ColorChanger from "../components/Providers/ColorChanger";
import Loader from "../components/Loader/Loader";

const GET_SINGLE = gql`

    query ( $section:[String] ) {
        entries (section: $section, limit: 1 ) {
            title
            id
            ... on impressum_impressum_Entry{
                text
            }
            ... on datenschutz_datenschutz_Entry{
                text
            }
        }
    }

`;

export const Single = props => {

    const {section} = props.match.params;

    const {loading, error, data} = useQuery(GET_SINGLE, {
        variables: {section: section},
    });

    return (
        <div className="SinglePage ">

            <CSSTransition
                in={loading}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
                appear
            >
                <Loader/>
            </CSSTransition>

            <CSSTransition
                in={error}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
                appear
            >
                <p>Error </p>
            </CSSTransition>

            <CSSTransition
                in={typeof data !== "undefined"}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
                appear
            >
                <div className={"container"}>
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            {
                                data ?
                                    data.entries.map(entry =>
                                        <div key={entry.id}>
                                            <ColorChanger hintergrundfarbe={'white'} schriftfarbe={'black'}/>
                                            <BreadcrumpChanger pageInfo={data}/>
                                            <p className={'h3'}>{entry.title}</p>
                                            <div dangerouslySetInnerHTML={{__html: entry.text}}
                                                 className="redactor-body fliesstext-klein mt-5"/>
                                        </div>
                                    ) : null
                            }
                        </div>
                    </div>
                </div>
            </CSSTransition>

        </div>
    );
};

