import React, {useState} from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const Image = props => {
    const [loaded, setLoaded] = useState(false);

    if (props.wraped) return (
        <RatioWrapper width={props.width} height={props.height}>
            <LazyLoadImage
                src={props.url}
                alt={props.title}
                width={props.width}
                height={props.height}
                className={`Image ${loaded ? "loaded" : "loading"}`}
                afterLoad={() => setLoaded(true)}
            />
        </RatioWrapper>
    )
    else return (
        <React.Fragment>
            <LazyLoadImage
                src={props.url}
                alt={props.title}
                width={props.width}
                height={props.height}
                className={`Image ${loaded ? "loaded" : "loading"}`}
                afterLoad={() => setLoaded(true)}
            />
        </React.Fragment>
    )
};

export const RatioWrapper = props => (
    <div className="ratio-wrapper" style={{paddingTop: ((props.height / props.width * 100) + "%")}}>
        <div className="ratio-wrapper-inner">
            {props.children}
        </div>
    </div>
)