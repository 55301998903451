import React from "react";
import {Image} from "../Image/Image";

const AusstellungCoverImage = props => {

    const isPortrait = ( props.height > props.width );

    return(
        <div className={`AusstellungCoverImage ${isPortrait?"isPortrait":""}`}>
            <Image {...props} />
        </div>
    );
}

export default AusstellungCoverImage;