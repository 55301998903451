import React from 'react';
import AusstellungsItem from "../AussteullungsItem/AusstellungsItem";
import PageHeader from "../PageHeader/PageHeader";

const AusstellungsGridView = props => {

    document.reverseCounter = 1;

    return (
        <div className="Ausstellungen">

            {props.aktuelle.length > 0 &&
            <div className="Ausstellungen-Aktuelle Ausstellungen-Section">


                <PageHeader>
                    Aktuelle Ausstellung{props.aktuelle.length > 1 && "en"}
                </PageHeader>

                <div className="ItemRow">
                    {props.aktuelle.map(item => (
                        <AusstellungsItem
                            key={item.id}
                            {...item}
                            size="large"
                        />
                    ))}
                </div>

            </div>
            }


            <div className="Ausstellungen-Kommende page-block-s Ausstellungen-Section">


                <PageHeader>
                    Kommende Ausstellungen
                </PageHeader>

                {renderItemRows(props.kommende)}
            </div>


            {
                Object.keys(props.vergangene).reverse().map((year, i) =>
                    <div key={year} className="Ausstellungen-Section">

                        <PageHeader>
                            {i === 0 && <div className={'mr-auto'}>Vergangene Ausstellungen</div>}
                            <div className="ml-auto text-tiny">{year}</div>
                        </PageHeader>

                        {renderItemRows(props.vergangene[year])}

                    </div>
                )
            }

        </div>
    )

}


const renderItemRows = items => {
    return items.map((item, i) => {
        if (i % 2 === 1) {
            return null
        } else {

            return (
                <div key={item.id} className="ItemRow">

                    {/* render current item */}
                    <AusstellungsItem
                        key={items[i].id}
                        {...items[i]}
                        size="small"
                        reverse={shouldReverse()}
                    />

                    {/* conditionally render next item if available */}
                    {items[i + 1] ?
                        <AusstellungsItem
                            key={items[i + 1].id}
                            {...items[i + 1]}
                            size="small"
                            reverse={shouldReverse()}
                        /> :
                        <div className="w-25">{shouldReverse()}</div>
                    }

                </div>
            )
        }
    })
}

const shouldReverse = () => {
    let reverse = false;
    if (document.reverserToggler === 0 || document.reverserToggler === 1) {
        reverse = true;
    }
    if (document.reverserToggler + 1 <= 3) {
        document.reverserToggler++;
    } else {
        document.reverserToggler = 0;
    }
    return reverse;
}

export default AusstellungsGridView;
