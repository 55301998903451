import React from 'react';

const Loader = props => {
    return(
        <div className="Loader text-tiny">
            ...
        </div>
    );
}

export default Loader;