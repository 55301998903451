import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { CSSTransition } from "react-transition-group";
import KuenstlerInnenView from "../components/KuenstlerInnenView/KuenstlerInnenView";
import BreadcrumpChanger from "../components/Providers/BreadcrumpChanger";
import ColorChanger from "../components/Providers/ColorChanger";
import Loader from "../components/Loader/Loader";

const GET_KUENSTLERINNEN = gql`

query {
    entries (section: "ausstellung") {
        typeHandle
        ... on ausstellung_teaser_Entry{
            title
            uri
            kuenstlerInnen {
                title
                id
            },
        }        
        ... on ausstellung_ausstellung_Entry{
            title
            uri
            kuenstlerInnen {
                title
                id
            },
        }
    }
    categories (group: "kuenstlerInnen") {
        ... on kuenstlerInnen_Category {
            title
#           TODO: remove next lin after removed from view:
            urlLink
            biografie{
                title
                url
            }
            land
        }
    }
}

`;

export const KuenstlerInnen = props => {

    const { loading, error, data } = useQuery(GET_KUENSTLERINNEN);

    return (
        <div className="SinglePage">

            <CSSTransition
                in={loading}
                classNames="fade"
                timeout={ 300 }
                unmountOnExit
                mountOnEnter
                appear
            >
                <Loader />
            </CSSTransition>

            <CSSTransition
                in={error}
                classNames="fade"
                timeout={ 300 }
                unmountOnExit
                mountOnEnter
                appear
            >
                <p>Error </p>
            </CSSTransition>

            <CSSTransition
                in={typeof data !== "undefined"}
                classNames="fade"
                timeout={ 300 }
                unmountOnExit
                mountOnEnter
                appear
            >
                <div>
                    <ColorChanger hintergrundfarbe={ 'white'} schriftfarbe={ 'black' } />
                    <BreadcrumpChanger pageInfo={data} />
                    <KuenstlerInnenView { ...data } />
                </div>
            </CSSTransition>

        </div>
    );
};

