import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { CSSTransition } from "react-transition-group";
import GalerieView from "../components/GalerieView/GalerieView";
import BreadcrumpChanger from "../components/Providers/BreadcrumpChanger";
import ColorChanger from "../components/Providers/ColorChanger";
import Loader from "../components/Loader/Loader";

const GET_UEBER = gql`

query {
    entries (section: "galerie") {
        title
        id
        ... on galerie_galerie_Entry {
            textDe
            textEn
            freunde {
                name
                url @transform(handle:"large")
            }
            bild {
                title
                url @transform(handle:"large")
            }
            pdfDownloads {
                title
                url
            }
        }
    }
    globalSet {
        ... on kontakt_GlobalSet {
            socialMediaLinks {
                name
                url
            }
        }
    }
}

`;

export const Galerie = props => {

    const { loading, error, data } = useQuery(GET_UEBER);

    return (
        <div className="SinglePage">

            <CSSTransition
                in={loading}
                classNames="fade"
                timeout={ 300 }
                unmountOnExit
                mountOnEnter
                appear
            >
                <Loader />
            </CSSTransition>

            <CSSTransition
                in={error}
                classNames="fade"
                timeout={ 300 }
                unmountOnExit
                mountOnEnter
                appear
            >
                <p>Error </p>
            </CSSTransition>

            <CSSTransition
                in={typeof data !== "undefined"}
                classNames="fade"
                timeout={ 300 }
                unmountOnExit
                mountOnEnter
                appear
            >
                <div>
                    <ColorChanger hintergrundfarbe={ 'white'} schriftfarbe={ 'black' } />
                    <BreadcrumpChanger pageInfo={data} />
                    <GalerieView { ...data } />
                </div>
            </CSSTransition>

        </div>
    );
};

