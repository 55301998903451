import React, {useState} from "react";


export const BreadcrumpContext = React.createContext({
    pageInfo: "",
    setPageInfo: () => {}
});


export const BreadcrumpProvider = props => {

    const setPageInfo = pageInfo => {
        setState({...state, pageInfo: pageInfo })
    }

    const initState = {
        pageInfo: "",
        setPageInfo: setPageInfo
    }

    const [state, setState] = useState(initState)

    return (
        <BreadcrumpContext.Provider value={state}>
            {props.children}
        </BreadcrumpContext.Provider>
    )
}
