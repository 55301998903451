import React, {useEffect, useState} from "react";
import {CSSTransition} from "react-transition-group";
import {Link} from "react-router-dom";


const Intro = props => {

    const [show, setShow] = useState(true);

    useEffect(() => {
        setTimeout(
            () => {
                setShow(false);
            }
            , 1000);

    }, []);

    return (
        <CSSTransition
            in={show}
            classNames="fade"
            timeout={500}
            unmountOnExit
        >
            <div className="Intro text-medium">
                <div className="Intro-Logo">
                    Galerie&nbsp;<strong>Monika&nbsp;Wertheimer</strong>
                </div>
            </div>
        </CSSTransition>
    );

}


export default Intro;