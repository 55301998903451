import React from "react";
import {Link} from "react-router-dom";


export const ExhibitionsList = props => {
    if (props.exhibitions.length > 0) {

        return (
            props.exhibitions.map(exhibition => (
                <Link
                    key={exhibition.title}
                    to={`/${exhibition.uri}`}
                    className={"text-tiny d-block"}
                >
                    {exhibition.title}
                </Link>
            ))
        );
    } else {
        return null;
    }
}