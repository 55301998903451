import React from 'react';
import DateManager from "../../utils/DateManager";
import {parseDate} from "../../utils/parseDate";

const AusstellungsText = props => {

    return (
        <div className={`
            AusstellungsText
            text-block
            text-center
            ${props.onlyText?"onlyText":""}
            `}
        >

             {/*Checking for Artist name and displaying them*/}
            {
                props.kuenstlerInnen.length > 0
                    ?
                    <p className={'preline text-tiny'}>
                        {props.kuenstlerInnen.map((kuenstler, index) => {
                            return `${kuenstler.title}${props.kuenstlerInnen.length - 1 !== index ? ", " : ""}`
                        })}
                    </p>
                    :
                    null
            }

            {/* Checking for Austellungs title and displaying them */}
            {<h2 className={"text-headline"}>{props.title}</h2> || null}


            {props.datumErsetzen ?
                <h3 className={"text-subheadline"}>
                    {props.datumErsetzen}
                </h3>
                :
                <React.Fragment>
                    {/* Checking for Begin and end and displaying them */}
                    {
                        props.datumAnfang && props.datumEnde
                            ?
                            <h3 className={"text-subheadline"}>
                                <DateManager date={props.datumAnfang} />
                                <span> – </span>
                                <DateManager date={props.datumEnde} />
                            </h3>
                            :
                            null
                    }

                    {/* Checking for Begin and displaying year and displaying them */}
                    {props.datumAnfang ? <p className={"text-tiny"}>{parseDate(props.datumAnfang).format('YYYY')}</p> : null}
                </React.Fragment>
            }


        </div>
    )
}

export default AusstellungsText;
