import React from "react";
import {parseDate} from "./parseDate";

const DateManager = props => {
    if (props.fullDate) {
        return (
            <span>
                {parseDate(props.date).format('LL')}
                <br/>
                {parseDate(props.date).format('HH:mm')}
           </span>
        );
    } else {
        return `${parseDate(props.date).format('ddd D. MMMM')}`;
    }
}

export default DateManager;