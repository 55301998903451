import React, {useEffect} from 'react';
import { ColorContext } from "./ColorProvider";

const ColorChanger = props => {
    return (
        <ColorContext.Consumer>
            {
                colorContext =>
                    <ColorSetter {...colorContext} {...props} />
            }
        </ColorContext.Consumer>
    )
}

export const ColorSetter = props => {

    useEffect(() => {

        props.setColors(props.hintergrundfarbe, props.schriftfarbe)

    }, [props.hintergrundfarbe, props.schriftfarbe])

    return null

}

export default ColorChanger;