import React from 'react';
import DateManager from "../../utils/DateManager";
import PageContainer from "../PageContainer/PageContainer";
import {Link} from 'react-router-dom'
import {parseDate} from "../../utils/parseDate";

const AusstellungsTableView = props => {

    let allExhibitions = props.sortObjectByYearValue(props.exhibitions, true)

    return (
        <PageContainer>

            <div className={'uebersicht-container container'}>

                {
                    allExhibitions.map((exhibition, i) => {
                        return (
                            <Link
                                key={`exhibition-${i}`}
                                to={`/${exhibition.uri}`}
                                className={'row uebersicht-row mb-3'}
                            >

                                {/* Title*/}
                                <div className={"col-6 "}>{exhibition.title || null}

                                    {/* KUENSTLER */}
                                    <div className={"text-tiny d-none d-lg-block"}>
                                        {
                                            exhibition.kuenstlerInnen.map((kuenstler, index) => {
                                                return `${kuenstler.title}${exhibition.kuenstlerInnen.length - 1 !== index ? ", " : ""}`
                                            })
                                        }
                                    </div>
                                </div>

                                {/* Termine*/}
                                <div className={"col-4 "}>
                                    {
                                        exhibition.datumAnfang && exhibition.datumEnde
                                            ?
                                            <React.Fragment>
                                                <DateManager date={exhibition.datumAnfang}/>
                                                <span> – </span>
                                                <DateManager date={exhibition.datumEnde}/>
                                            </React.Fragment>
                                            :
                                            null
                                    }
                                </div>


                                {/* YEAR */}
                                <div className={"col-2 text-tiny text-right"}>
                                    { parseDate(exhibition.datumAnfang).format('YYYY') }
                                </div>

                            </Link>
                        )
                    })
                }

            </div>
        </PageContainer>
    )

}

export default AusstellungsTableView;
