import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Ausstellungsuebersicht } from './containers/Ausstellungsuebersicht'
import { Single } from './containers/Single'
import { Ausstellung } from "./containers/Ausstellung";
import { KuenstlerInnen } from "./containers/KuenstlerInnen";
import { Galerie } from "./containers/Galerie";
import { Photos } from "./containers/Photos"

class Routing extends Component {
    render () {
        return (
            <div className="page-content">
                <Switch>

                    <Route path={"/ausstellungen"} component={ Ausstellungsuebersicht }/>
                    <Route path={"/ausstellung/:exhibitionSlug"} exact component={ Ausstellung }/>
                    <Route path={"/kuenstler-innen"} exact component={ KuenstlerInnen }/>

                    <Route path={"/photos"} exact component={ Photos }/>
                    <Route path={"/photos/:bildTitle"} exact component={ Photos }/>

                    <Route path={"/:section(impressum|datenschutz)"} exact component={ Single }/>
                    <Route path={"/galerie"} exact component={ Galerie }/>

                    <Redirect to="/galerie" />

                </Switch>
            </div>
        );
    }
}

export default Routing;
