import React from 'react';
import PageContainer from "../PageContainer/PageContainer";
import NavigationLinks from "../navigation/NavigationLinks";
import {Link} from "react-router-dom";
import {ExhibitionsList} from "./ExhibitionsList";
import PageHeader from "../PageHeader/PageHeader";

class KuenstlerInnenView extends React.Component {

    constructor(props) {
        super(props);

        this.sortKuenstlerInnen = this.sortKuenstlerInnen.bind(this)

        this.state = {
            kuenstlerInnen: {}
        }
    }

    componentDidMount() {
        this.sortKuenstlerInnen()
    }

    sortKuenstlerInnen = () => {
        let kuenstlerInnen = this.props.categories

        // Sort array Alphabetically
        kuenstlerInnen.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase()) ? 1 : ((b.title.toLowerCase() > a.title.toLowerCase()) ? -1 : 0));

        // Split Array into Letter Objects
        let kuenstlerCacheObj = {}
        kuenstlerInnen.forEach((kuenstler, i) => {

            // Checking each exhibition and artist
            kuenstler.exhibitions = this.props.entries.filter(entry => {
                let isTheSame = false
                if (entry.typeHandle === 'teaser') return false
                entry.kuenstlerInnen.forEach(exhibitionKuenstler => {
                    if (exhibitionKuenstler.title === kuenstler.title) isTheSame = true
                })
                return isTheSame
            })

            let currentStartingLetter = kuenstler.title.slice(0, 1).toLowerCase()
            if (kuenstlerInnen[i - 1] === undefined || currentStartingLetter.toLowerCase() !== kuenstlerInnen[i - 1].title.slice(0, 1).toLowerCase()) {
                kuenstlerCacheObj[currentStartingLetter] = [kuenstler]
            } else {
                kuenstlerCacheObj[currentStartingLetter].push(kuenstler)
            }
        })

        this.setState({kuenstlerInnen: kuenstlerCacheObj})
    }

    render() {

        return (
            <PageContainer classNames={"KuenstlerInnenView"}>

                <PageHeader>
                    <h3 className={'text-uppercase text-tiny'}>Künstler*innen</h3>
                </PageHeader>

                <div className={'page-block first'}>
                    {
                        Object.keys(this.state.kuenstlerInnen).length > 0
                            ?
                            Object.keys(this.state.kuenstlerInnen).map((letter, i) => {
                                return (
                                    <React.Fragment key={`fragment-${i}`}>

                                        <div
                                            key={`letter-${i}`}
                                            className={'container-fluid kuenstlerIn-Letter letter-section text-tiny'}>
                                            {letter.toUpperCase()}
                                        </div>

                                        <div
                                            className={"kuenstler-view container"}
                                            key={`letterk-${i}`}
                                        >
                                            {
                                                this.state.kuenstlerInnen[letter].map((kuenstlerIn, i) => {
                                                    return (
                                                        <div
                                                            className={'kuenstler-item '}
                                                            key={kuenstlerIn.id + "" + i}
                                                        >


                                                            <div className={'text-headline'}>
                                                                {kuenstlerIn.title.toUpperCase()}
                                                            </div>

                                                            <div className="text-tiny keunstler-land">
                                                                {kuenstlerIn.land}
                                                            </div>


                                                            <div className="keunstler-bio text-tiny">
                                                                {
                                                                    kuenstlerIn.biografie.map(bio =>
                                                                        <a
                                                                            key={bio.title}
                                                                            href={bio.url}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            → Biografie
                                                                        </a>
                                                                    )
                                                                }
                                                            </div>


                                                            <ExhibitionsList exhibitions={kuenstlerIn.exhibitions}/>


                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                    </React.Fragment>
                                )
                            })
                            :
                            null
                    }
                </div>


            </PageContainer>
        )
    }

}

export default KuenstlerInnenView;
