import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import NavigationLinks from "./NavigationLinks";
import Breadcrump from "./Breadcrump";
import {HamburgerIcon} from "../Icons/Hamburger";
import {ScrollPositionContext} from "../Providers/ScrollPositionProvider";
import {ColorContext} from "../Providers/ColorProvider";

const Navigation = props => {

    const [show, setShow] = useState(false);

    // close Nav after location change
    useEffect(() => {
        setShow(false);
    }, [props.location.key]);

    useEffect(()=>{
        if(show){
            document.body.classList.add("navigation-open");
        }else{
            document.body.classList.remove("navigation-open");
        }
    },[show])

    return (
        <ScrollPositionContext.Consumer>
            {({setTop}) =>
                <ColorContext.Consumer>
                    {colorContext =>
                        <div
                            className={`Navigation ${show ? "show" : ""}`}
                            style={{
                                color: colorContext.schriftfarbe,
                                backgroundColor: colorContext.hintergrundfarbe,
                                // color: colorContext.hintergrundfarbe,
                                // backgroundColor: colorContext.schriftfarbe,
                            }}
                        >


                            {/* ADDED ONCLICK EVENT ON DIV, TO REMOVE IT ON Route change */}
                            <div onClick={() => setShow(false)} className="NavigationLinks-Wrapper">
                                <NavigationLinks/>
                            </div>

                            {/* Resetting the ScrollPosition*/}
                            <div onClick={() => setTop(0)} className={'Logo text-uppercase text-medium'}>
                                <Link to={"/"}>
                                    Galerie <strong>Monika Wertheimer</strong>
                                </Link>
                            </div>


                            <div className={'Navigation-Toggler'}>
                                <Breadcrump/>
                                <button className="Toggle-Button button-hover-scale button-blank" onClick={() => setShow(!show)}>
                                    <HamburgerIcon active={show}/>
                                </button>
                            </div>


                        </div>
                    }
                </ColorContext.Consumer>
            }
        </ScrollPositionContext.Consumer>
    )
}

export default Navigation;
