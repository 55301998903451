import React from 'react';
import {Link} from "react-router-dom";
import {Addresse, Kontakt, Oeffnungszeiten} from "../Globals/Globals";

const FooterView = props => {

    return (
        <div className={'container-fluid footer text-tiny'}>
            <div className="row">

                {/*  Adresse  */}
                <div className="col-xs-12 col-sm-6 col-lg-3 offset-lg-6 footer-section">
                    <Addresse />
                </div>

                {/* Kontakt */}
                <div className="col-xs-12 col-sm-6 col-lg-3 footer-section">
                    <Kontakt />
                </div>

                {/* Oeffnungszeiten */}
                <div className="col-xs-12 col-sm-6 col-lg-3 offset-lg-6 footer-section">
                    <Oeffnungszeiten />
                </div>

                {/* STATIC LINKS */}
                <div className="col-xs-12 col-sm-6 col-lg-3  footer-section">
                    <Link to={"/impressum"}>Impressum</Link>
                    <br/>
                    <Link to={"/datenschutz"}>Datenschutz</Link>
                </div>

            </div>

        </div>
    )
}

export default FooterView;
