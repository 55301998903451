import React from 'react';
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import {BreadcrumpContext} from "../Providers/BreadcrumpProvider";

const BreadcrumpWrapper = props => {
    return (
        <BreadcrumpContext.Consumer>
            {({pageInfo}) =>
                <Breadcrump {...props} pageInfo={pageInfo} />
            }
        </BreadcrumpContext.Consumer>
    )
}

const Breadcrump = props => {

    // Getting the current Pathname
    let { pathname } = useLocation()
    pathname = pathname.split('/')
    pathname = pathname.filter(el => { return el !== '' })

    // Creating an Array which holds the slugs and titles
    let toDisplay = []
    // Creating the first Title
    // If Lenght is 0 ist »home«
    let title = pathname.length > 0 ? pathname[0].charAt(0).toUpperCase() + pathname[0].slice(1) : null

    // Special Treatment for the Title
    if (title === 'Bild-des-monats') {
        title = 'Bild des Monats'
    } else if (title === 'Ausstellung') {
        title = 'Ausstellungen'
    } else if (title === 'Kuenstler-innen') {
        title = 'Kuenstler*innen'
    }

    // Pushing the first Breadcrump
    // Checking for the Ausstekkuneg, because the Link ist slightly different
    toDisplay.push({slug: pathname[0] === 'ausstellung' ? 'ausstellungen' : pathname[0], title: title})

    // Checking if data ha arrived
    if (props.pageInfo) {
        // checking if were in more than one level
        if (pathname.length > 1) {

            switch (pathname[0]) {
                case "ausstellung":
                    if (props.pageInfo.entries) toDisplay.push({slug: props.pageInfo.entries[0].uri || null, title: props.pageInfo.entries[0].title || null})
                    break;
                case "photos":
                    if (props.pageInfo.entries) {
                        let current = props.pageInfo.entries.filter(entry => {
                            return entry.uri === pathname.join('/')
                        })
                        toDisplay.push({slug: current.length > 0 ? current[0].uri : null, title: current.length > 0 ? current[0].title : null})
                    }
                    break;
                default:
                    toDisplay.push({slug: `${pathname[0]}/${pathname[1]}`, title: pathname[1].charAt(0).toUpperCase() + pathname[1].slice(1)})
            }
        }
    }

    return (
        <div className={'breadcrump d-none d-md-block text-tiny'}>
            {
                toDisplay.length > 0
                    ?
                    toDisplay.map((entry, i) => {
                        return (
                            <React.Fragment key={`breadcrump-${i}`}>
                                <Link key={`breadcrump-${i}`} to={`/${entry.slug}`}>{entry.title}</Link>
                                { i + 1 < toDisplay.length ? <span>&nbsp;–&nbsp;</span> : null}
                            </React.Fragment>
                        )
                    })
                    :
                    null
            }
        </div>
    )
}

export default BreadcrumpWrapper;
