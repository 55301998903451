import React from "react";
import strToHtml from "../../utils/strToHtml";
import {Link} from "react-router-dom";

export const Addresse = props => {
    return (
        <div>

            <p className={'preline mb-0'}>
                Galerie Monika Wertheimer
            </p>

            {
                // if a link to google maps exists, link the adress, otherwise just render the text
                window.initialDataset.globalSet.mapsUrl !== "" ?
                <a
                    href={window.initialDataset.globalSet.mapsUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <p
                        dangerouslySetInnerHTML={strToHtml(window.initialDataset.globalSet.adresse)}
                        className={'mb-0'}
                    />
                </a>
                :
                <p
                    dangerouslySetInnerHTML={strToHtml(window.initialDataset.globalSet.adresse)}
                    className={'mb-0'}
                />
            }

        </div>
    )
}





export const Kontakt = props => {
    return (
        <div>

            <p
                dangerouslySetInnerHTML={strToHtml(window.initialDataset.globalSet.telefon)}
                className={'mb-0'}
            />
            <p className={'mb-0'}>
                <a href={`mailto:${window.initialDataset.globalSet.mail}`}>{window.initialDataset.globalSet.mail}</a>
                <br/>
                <Link to={window.initialDataset.globalSet.siteUrl}>galeriewertheimer.ch</Link>
            </p>

        </div>
    )
}
export const Oeffnungszeiten = props => {
    return (
        <div>
            <p className={'preline mb-0'}
               dangerouslySetInnerHTML={strToHtml(window.initialDataset.globalSet.oeffnungzeiten)}
            />
        </div>
    )
}


