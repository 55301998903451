import React, {useEffect} from 'react';
import { BreadcrumpContext } from "./BreadcrumpProvider";


const BreadcrumpChanger = props => {
    return (
        <BreadcrumpContext.Consumer>
            {
                breadcrumpContext =>
                    <Breadcrump {...breadcrumpContext} {...props} />
            }
        </BreadcrumpContext.Consumer>
    )
}

export const Breadcrump = props => {

    useEffect(() => {
        props.setPageInfo(props.pageInfo)
    }, [props.pageInfo])

    return null
}

export default BreadcrumpChanger;
