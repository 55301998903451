import React from "react";
import {Image} from "../Image/Image";

export const AusstellungItemBild = props => {
    return (
        <div className={`
            AusstellungItemBild 
            ${ props.size } 
            ${ props.bild.height > props.bild.width ? "fit-height" : "fit-width" }
        `}>

            <Image {...props.bild} />

        </div>
    );
}
