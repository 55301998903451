import React from 'react';

import { ApolloProvider } from '@apollo/react-hooks';
import { apiClient } from "./apiClient";

export const DataProvider = props => (
    <ApolloProvider client={ apiClient }>
        { props.children }
    </ApolloProvider>
);
