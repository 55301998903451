import React from 'react';
import DateManager from "../../utils/DateManager";

export const AusstellungTermin = props => {


    return (
        <div className={"AusstellungTermin text-tiny"}>
            <p>
                <strong>{props.terminTitel}</strong>
                <br/>
                <DateManager date={props.terminDatum} fullDate />
            </p>
        </div>
    )

}
