import React from 'react';
import NavigationLinks from "../navigation/NavigationLinks";
import PageContainer from "../PageContainer/PageContainer";
import AusstellungsTableView from "./AusstellungsTableView";
import AusstellungsGridView from "./AusstellungsGridView";
import {ViewButton} from "../Icons/ViewButton"
import {Switch, Route, Link} from "react-router-dom";


const AusstellungsuebersichtView = props => {
    return (

        <PageContainer classNames={'AusstellungUebersicht'}>


            {/*
            <div className="container-fluid page-block-xs">
                <div className="AusstellungUebersicht-ToggleWrapper ">
                    <Route path="/ausstellungen/tabelle" children={({match}) => {
                        return (
                            <Link to={match ? "/ausstellungen" : "/ausstellungen/tabelle"}
                                  className={"button-hover-scale"}>
                                <ViewButton active={match}/>
                            </Link>
                        );
                    }}/>

                </div>
            </div>
            */}


            <Switch>
                {/* GRIDVIEW */}
                <Route
                    path="/ausstellungen"
                    render={() => {
                        // Checking if exhibitions exists
                        return <AusstellungsGridView {...props}/>
                    }}
                />

            </Switch>


        </PageContainer>
    )
}

class AusstellungsuebersichtViewOld extends React.Component {

    render() {
        return (
            <PageContainer className={'AusstellungUebersicht'}>





                <Switch>
                    {/* TABLEVIEW */}
                    <Route
                        path="/ausstellungen/tabelle"
                        exact
                        render={() => {
                            // Checking if exhibitions exists
                            if (Object.keys(this.state.exhibitions).length > 0)
                                return <AusstellungsTableView sortObjectByYearValue={this.sortObjectByYearValue}
                                                              exhibitions={this.props.entries}/>
                            else return null
                        }}
                    />
                    {/* GRIDVIEW */}
                    <Route
                        path="/ausstellungen"
                        render={() => {
                            // Checking if exhibitions exists
                            if (Object.keys(this.state.exhibitions).length > 0)
                                return <AusstellungsGridView exhibitions={this.state.exhibitions}/>
                            else return null
                        }}
                    />
                </Switch>


                <div className="page-block">
                    <NavigationLinks bottom={true}/>
                </div>

            </PageContainer>
        )
    }

}

export default AusstellungsuebersichtView;
