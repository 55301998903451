import React from 'react';
import {NavLink} from "react-router-dom";
import AusstellungsText from "./AusstellungText";
import {AusstellungItemBild} from './AusstellungItemBild';
import {ScrollPositionContext} from "../Providers/ScrollPositionProvider";

const AusstellungsItem = props => {

    // Possible Types: 1. only-text, 2. aktuelle und 3. restlich


    if (props.type === "ausstellung") {
        return (
            <ScrollPositionContext.Consumer>
                {({setTop}) =>
                    <NavLink
                        className={`
                            AusstellungsItem
                            ${props.type}
                            ${props.reverse ? 'order-reverse' : ''}
                        `}
                        to={`/${props.uri}`}
                        onClick={() => setTop(window.pageYOffset)}
                    >

                        {/* Placing Image */}
                        {
                            props.bild.length >= 1 ?
                                <AusstellungItemBild bild={props.bild[0]} size={props.size}/>
                                : null
                        }
                        <AusstellungsText {...props} />
                    </NavLink>
                }
            </ScrollPositionContext.Consumer>
        )
    } else {
        return (
            <div
                className={`
                AusstellungsItem
                ${props.type}
            `}>
                <AusstellungsText {...props} onlyText/>
            </div>
        )
    }
}

export default AusstellungsItem;


