import React, {useState} from "react";


export const ColorContext = React.createContext({
    schriftfarbe: "#000000",
    hintergrundfarbe: "#FFFFFF",
    setColors: () => {}
});


export const ColorProvider = props => {

    const setColors = (hintergrund, schrift) => {
        setState({...state, schriftfarbe: schrift, hintergrundfarbe: hintergrund});

        document.documentElement.style.backgroundColor = hintergrund;
        document.documentElement.style.color = schrift;
    }

    const initState = {
        schriftfarbe: "#000000",
        hintergrundfarbe: "#FFFFFF",
        setColors: setColors
    }

    const [state, setState] = useState(initState)

    return (
        <ColorContext.Provider value={state}>
            {props.children}
        </ColorContext.Provider>
    )
}


