import React from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import Routing from './Routing';
import Navigation from "./components/navigation/Navigation";
import {DataProvider} from "./utils/DataProvider";
import {ColorProvider} from "./components/Providers/ColorProvider"
import {BreadcrumpProvider} from "./components/Providers/BreadcrumpProvider";
import {ScrollPositionProvider} from "./components/Providers/ScrollPositionProvider";
import {ScrollManager} from "./utils/ScrollManager";
import {DataCacheProvider} from "./components/Providers/DataCacheProvider";
import Intro from "./components/Intro/Intro";
import FooterView from "./components/footer/FooterView";

function App() {
    return (
        <Router>
            <ScrollPositionProvider>
                <ColorProvider>
                    <DataCacheProvider>
                        <div className="App">
                            <BreadcrumpProvider>
                                <DataProvider>
                                    <Intro/>
                                    <Route component={ScrollManager}/>
                                    <Route component={Navigation}/>
                                    <Routing/>
                                    <FooterView />
                                </DataProvider>
                            </BreadcrumpProvider>
                        </div>
                    </DataCacheProvider>
                </ColorProvider>
            </ScrollPositionProvider>
        </Router>
    );
}

export default App;
