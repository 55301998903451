import React from 'react';
import {useQuery} from '@apollo/react-hooks';
import {Redirect} from "react-router-dom";
import gql from 'graphql-tag';
import {CSSTransition} from "react-transition-group";
import AusstellungsViewWrapper from "../components/AusstellungsView/AusstellungView";
import BreadcrumpChanger from "../components/Providers/BreadcrumpChanger";
import ColorChanger from "../components/Providers/ColorChanger";
import Loader from "../components/Loader/Loader";

const QUERY_AUSTELLUNG = gql`
    query ( $slug:[String] ) {
        entries (section: "ausstellung", slug: $slug ) {
            slug
            id
            uri
            title
            ... on ausstellung_ausstellung_Entry {
                kuenstlerInnen {
                    title
                    id
                    biografie{
                        title
                        url
                    }
                },
                datumAnfang
                datumErsetzen
                datumEnde
                bild {
                    id
                    title
                    url @transform( handle:"large" )
                    width
                    height
                }
                bilder {
                    id
                    title
                    url @transform( handle:"large" )
                    width
                    height
                    kuenstlerInnen {
                        title
                        id
                    }
                    entstehungsjahre
                    bildtitelAnzeigen
                    auflage
                    entstehungsjahre
                    technik
                }
                schriftfarbe
                hintergrundfarbe
                text

                pdfDownloads {
                    id
                    title
                    url
                }
                termine {
                    ... on termine_termin_BlockType {
                        terminTitel
                        terminDatum
                        terminInfos
                    }
                }
            }
        }
    }
`;

export const Ausstellung = props => {

    const {exhibitionSlug} = props.match.params;

    const {loading, error, data} = useQuery(QUERY_AUSTELLUNG, {
        variables: {slug: exhibitionSlug}
    });

    // check if entry exists
    const entryExists = data && data.entries && data.entries.length > 0;

    return (
        <div>

            <CSSTransition
                in={loading}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
                appear
            >
                <Loader/>
            </CSSTransition>

            <CSSTransition
                in={typeof error !== "undefined"}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
                appear
            >
                <p>error</p>
            </CSSTransition>


            <CSSTransition
                in={typeof data !== "undefined"}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
                appear
            >

                <div>
                    {entryExists ?
                        <React.Fragment>
                            <ColorChanger
                                hintergrundfarbe={data ? data.entries[0].hintergrundfarbe : null}
                                schriftfarbe={data ? data.entries[0].schriftfarbe : null}
                            />
                            <BreadcrumpChanger pageInfo={data}/>
                            <AusstellungsViewWrapper {...data} />
                        </React.Fragment>
                        :
                        <Redirect to="/ausstellungen"/>
                    }
                </div>


            </CSSTransition>


        </div>
    );

};
