import React from 'react';
import {Link, NavLink} from "react-router-dom";
import {ReactComponent as ArrowWest} from "../../static/W_Arr.svg";
import SvgIconWrapper from "../Icons/SvgIconWrapper";

const NavigationLinks = props => {

    return (
        <div className={'NavigationLinks text-headline'}>

            {
                props.isAusstellung &&
                <Link to={"/ausstellungen"} className={"button-hover-scale button-blank"}>
                    <SvgIconWrapper>
                        <ArrowWest/>
                    </SvgIconWrapper>
                </Link>
            }
            <NavLink to={"/galerie"} activeClassName={"activeLink"}>Galerie</NavLink>
            <NavLink to={"/ausstellungen"} activeClassName={"activeLink"}>Ausstellungen</NavLink>
            <NavLink to={"/kuenstler-innen"} activeClassName={"activeLink"}>Künstler*innen</NavLink>
            <NavLink to={"/photos"} activeClassName={"activeLink"}>Photos</NavLink>

        </div>
    );

}

export default NavigationLinks;
