import React, {useState} from "react";


export const ScrollPositionContext = React.createContext({
    top: 0,
    setTop: () => {}
});


export const ScrollPositionProvider = props => {

    const setTop = top => {
        setState({...state, top: top})
    }

    const initState = {
        top: 0,
        setTop: setTop
    }

    const [state, setState] = useState(initState)

    return (
        <ScrollPositionContext.Provider value={state}>
            {props.children}
        </ScrollPositionContext.Provider>
    )
}
