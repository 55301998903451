import React from 'react';
import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {CSSTransition} from "react-transition-group";
import PhotosView from "../components/PhotosView/PhotosView";
import BreadcrumpChanger from "../components/Providers/BreadcrumpChanger";
import ColorChanger from "../components/Providers/ColorChanger";
import Loader from "../components/Loader/Loader";
import {Route} from 'react-router-dom'

const GET_BILD_DES_MONATS = gql`

    query {
        entries (section: "photos" orderBy: "lft" ) {
            ... on photos_photos_Entry {
                title
                slug
                id
                uri
                preis
                entstehungsjahre
                kuenstlerInnen {
                    title
                    id
                }
                bild {
                    url @transform( handle:"large" )
                    id
                }
                title
                auflage
                technik
            }
        }
        globalSet {
            ... on kontakt_GlobalSet {
                adresse
                telefon
                mail
            }
        }
    }

`;

export const Photos = props => {

    const {loading, error, data} = useQuery(GET_BILD_DES_MONATS);

    return (
        <div>
            <CSSTransition
                in={loading}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
                appear
            >
                <Loader/>
            </CSSTransition>

            <CSSTransition
                in={typeof error !== "undefined"}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
                appear
            >
                <p>Error </p>
            </CSSTransition>

            <CSSTransition
                in={typeof data !== "undefined"}
                classNames="fade"
                timeout={300}
                unmountOnExit
                mountOnEnter
                appear
            >
                <div>
                    <ColorChanger hintergrundfarbe={'white'} schriftfarbe={'black'}/>
                    <BreadcrumpChanger pageInfo={data}/>
                    <Route path="/photos/:slug" children={route => <PhotosView {...data} {...route} />}/>
                </div>
            </CSSTransition>

        </div>
    );
};

