import React from 'react';
import {AusstellungTermin} from "./AusstellungTermin";

export const AusstellungTermine = props => {
    if (props.gridView) {
        return (
            <div className="AusstellungTermine-Grid col-12 col-md-6 offset-md-3 redactor-default ">
                <div className="row">

                    {
                        props.termine.map((termin, i) => {
                            return (
                                <div key={`termin-${i}`} className="col-12 col-md-6 redactor-default">
                                    <AusstellungTermin key={`termin-${i}`} {...termin} />
                                </div>
                            )
                        })
                    }

                    {props.pdfDownloads.length > 1 &&
                    <div className="col-12 redactor-default text-tiny">
                        {
                            props.pdfDownloads.map((download, i) => {
                                return (
                                    <a
                                        href={download.url}
                                        key={`pdf-${i}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        ↓ {download.title}
                                    </a>
                                )
                            })
                        }
                    </div>
                    }


                </div>
            </div>
        );
    } else {
        return (

            <div className="col-12 col-md-3 redactor-default">
                {
                    props.termine.map((termin, i) => {
                        return (
                            <AusstellungTermin key={`termin-${i}`} {...termin} />
                        )
                    })
                }
            </div>

        )
    }
}
