import React from "react"
import {Image} from "../Image/Image";
import {Link, useLocation } from "react-router-dom";

export const GridView = props => {
    let { pathname } = useLocation()

    return(
        <div className="row">
            {
                props.entries.map((entry) => {
                    return (
                        !(entry.uri === pathname.slice(1))
                            ?
                            <Link
                                to={`/${entry.uri}`}
                                key={entry.id}
                                className={'single-img page-block-xs col-6 col-md-4 col-lg-3'}
                            >
                                <Image {...entry.bild[0]} />
                            </Link>
                            :
                            null
                    )
                })
            }
        </div>
    )
}
