import React from "react"
import {Image} from "../Image/Image";

export const SingleView = props => {


    return (
        <div className="PhotosSingleView container">

            <div className="row">


                <div className="PhotosSingleView-ImageContainer col-12 col-lg-8 offset-lg-2">


                    {/* Bild Block */}
                    <div className="page-block-s mt-0">
                        <Image {...props.bild[0]} />
                    </div>


                    {/* Meta Block */}
                    <div className={"text-center "}>


                        {/* Name Künstler*inn */}
                        <p className={'preline text-tiny mb-0'}>
                            {
                                props.kuenstlerInnen.map((kuenstler, index) => {
                                    return `${kuenstler.title}${props.kuenstlerInnen.length - 1 !== index ? ", " : ""}`
                                })
                            }
                        </p>


                        {/* Titel, Jahr */}
                        <p className={"text-headline"}>
                            {props.title}
                            {props.entstehungsjahre && ", " + props.entstehungsjahre}
                        </p>


                        <p>

                            {props.auflage &&
                            <span>Auflage: {props.auflage}</span>
                            }

                            {props.technik &&
                            <span><br/> {props.technik}</span>
                            }

                        </p>


                        {/* Preis… auf Anfrage */}
                        <p className={'text-tiny'}>
                            Preis:
                            <br/>
                            {props.preis && `CHF ${props.preis.toFixed(2).replace(".", ",").replace(",00", ".-")}`}
                            {props.preis && <br/>}
                            <a href={`mailto:${window.initialDataset.globalSet.mail}?subject=Anfrage für das Bild: ${props.title}`}>
                                <u>{!props.preis && "Auf"} Anfrage</u>
                            </a>
                        </p>

                    </div>


                </div>
            </div>


        </div>

    )
}
