import React from "react";
import NavigationLinks from "../navigation/NavigationLinks";

const PageContainer = props => (
    <div className={`PageContainer ${props.classNames}`}>

        {props.children}

        {/* BOTTOM NAVIGATION */}
        <div className="page-block">
            <NavigationLinks bottom isAusstellung={props.isAusstellung} />
        </div>

    </div>
);

export default PageContainer;